import { Icons } from '../../icons';

export enum CreditCardBrands {
	Visa = 'Visa',
	MasterCard = 'MasterCard',
	AmericanExpress = 'AmericanExpress',
	Discover = 'Discover'
}

export const creditCardBrandIconMap = new Map<CreditCardBrands, JSX.Element>([
	[CreditCardBrands.Visa, Icons.Visa],
	[CreditCardBrands.MasterCard, Icons.MasterCard],
	[CreditCardBrands.AmericanExpress, Icons.AmericanExpress],
	[CreditCardBrands.Discover, Icons.Discover]
]);
